
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";

import moment from 'moment';
import React, { useEffect, useState } from "react";
import { ReactComponent as Refresh } from "../../../assets/images/refresh_Icon.svg";
import IconHandler from "../../../components/IconHandler";
import CustomDropDownDatePicker from "../../../components/CustomDropDownDatePicker";
import DataTable from "../../../components/DataTable";
import RightViewLayout from "../../../components/RighViewLayout";
import {
    deleteTemplate,
    getAuditTemplates,
} from "../../../services/AdvertService";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Visibility } from "../../../assets/images/View.svg";
import { ReactComponent as DeleteOutlineIcon } from "../../../assets/images/Delete Icon (2).svg";
import { ReactComponent as ModeEditIcon } from "../../../assets/images/Edit Icon (1).svg";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "antd";
import { REACT_EDIT_TEMPLATE, REACT_TEMPLATE_IMAGES } from "../../../constants/ReactEndPoints";
import { toast } from "react-toastify";
import { t } from "i18next";
import { makeStyles } from "@material-ui/core";

const options = [
    { value: 'Today', label: 'Today' },
    { value: 'Yesterday', label: 'Yesterday' },
    { value: 'Week', label: 'Week' },
    { value: 'Month', label: 'Month' },
];

export default function TemplateAuditList() {
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [rows, setRows] = useState([]);
    const [callApiFilter, setcallApiFilter] = useState(true);
    const [selectedValue, setSelectedValue] = React.useState("");
    const [id, setId] = useState("");
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");



    const useStyles = makeStyles((theme) => ({
        customClass: {
            '& .MuiDialog-paper': {
                backgroundColor: 'transparent',
            },
        },
        btnStyle: {
            color: '#fff',
            cursor: 'pointer'
        }
    }));
    const classes = useStyles();
    const handleClose = () => {
        setOpen(false);
        setConfirm(false);
    };

    const [pageOperation, setPageOperation] = useState({
        page: 0,
        rowsPerPage: 10,
    });
    const formatDate = (dateString) => {
        const originalDate = moment(dateString);
        const formattedDate = originalDate.format('DD MMM YYYY HH:mm:ss');
        return formattedDate;
    };

    const loadData = () => {
        let query = "";
        if (userFilter) {
            query += "&filterAdvert=" + userFilter;
        }
        const country = localStorage.getItem("COUNTRY");
        if (query === "") {
            getAuditTemplates(pageOperation.page + 1, pageOperation.rowsPerPage, "", country).then(
                (res) => {
                    if (res.data.code === "ZKCSAI0001") {
                        console.log(res.data.data.zkadverts)

                        if (res.data.code === "ZKCSAI0001") {
                            setRows(res.data.data.zkadverts);
                            setTotalRecords(res?.data?.data?.totalCount);
                        } else {
                            setRows([]);
                            setTotalRecords(0);
                        }

                    }
                }
            );
        } else if (query !== "") {
            getAuditTemplates(pageOperation.page + 1, pageOperation.rowsPerPage, query, country).then((res) => {
                if (res != undefined) {
                    if (res.data.code === "ZKCSAI0001") {
                        setRows(res.data.data.zkadverts);
                        setTotalRecords(res?.data?.data?.totalCount);
                    } else {
                        setRows([]);
                        setTotalRecords(0);
                    }
                }
            });
        }
    };


    useEffect(() => {
        if (callApiFilter) {
            userFilter = selectedValue
            loadData();

            setcallApiFilter(false);
        }
    }, [callApiFilter]);


    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 50,
            flex: 1,
            renderCell: (e) => e.row.id,
            hide: true,
        },
        {
            field: "thumbnailImage",
            headerName: t("TEM2"),
            width: 200,
            flex: 1,
            renderCell: (e) => (
                <img
                    src={e.row.thumbnailImage}
                    alt="Template Thumbnail"
                    draggable="false"
                    onClick={() => handleOpen(e.row.thumbnailImage)}
                    style={{ width: '60px', height: '60px', paddingBottom: '10px', paddingTop: '10px' }}
                />
            ),
        },
        {
            field: "templateName",
            headerName: t("TEM3"),
            width: 200,
            flex: 1.8,
            renderCell: (e) =>
            (<Tooltip title={e.row.templateName} placement="bottom-start">
                {e.row.templateName}
            </Tooltip>)
        },
        {
            field: "createdAt",
            headerName: t("TEM4"),
            width: 200,
            flex: 1.5,
            renderCell: (e) =>
            (<Tooltip title={formatDate(e.row.createdAt)} placement="bottom-start">
                {formatDate(e.row.createdAt)}
            </Tooltip>)
        },
        {
            field: "createdBy",
            headerName: t("TEM5"),
            width: 200,
            flex: 1,
            renderCell: (e) => e.row.createdBy,
        },
        {
            field: "tags",
            headerName: t("TEM6"),
            width: 200,
            flex: 1.5,
            renderCell: (e) =>
            (<Tooltip title={e.row.tags} placement="bottom-start">
                {e.row.tags}
            </Tooltip>)
        },

        {
            field: "description",
            headerName: t("TEM7"),
            width: 200,
            flex: 1,
            renderCell: (e) => e.row.description,
        },

        {
            headerName: t("USER10"),
            sortable: false,
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (e) => (
                <IconHandler>

                    <Tooltip title={t("USER11")} arrow>
                        <ModeEditIcon id="auditTempList-edit"
                            onClick={() => handleActions("Edit", e)}
                            style={{ margin: "7px", width: '1em'}}
                        />
                    </Tooltip>
                    <Tooltip title={t("USER12")} arrow>
                        <DeleteOutlineIcon id="auditTempList-delete"
                            onClick={() => handleActions("Delete", e)}
                            style={{ margin: "7px", width: '0.8em'}}
                        />
                    </Tooltip>

                </IconHandler>
            ),
        }
    ];

    const handleActions = (action, data) => {
        const templateId = data.id;
        setId(templateId);
        if (action === "View") {
            navigate(REACT_TEMPLATE_IMAGES, { state: { templateId } });
        } else if (action === "Delete") {
            setId(data['id'])
            setConfirm(true);
        } else if (action === "Edit") {
            setId(data['id'])
            console.log(id)
            const templateId = data['id'];
            navigate(REACT_EDIT_TEMPLATE, { state: { templateId } });
        }

    }

    const handleDelete = (id) => {
        deleteTemplate(id).then((response) => {
            if (response != undefined) {
                if (response.data.code === "ZKCSAI0001") {
                    toast.success(t("TEM9"), {
                        position: "top-right",
                    });
                    setConfirm(false);
                    loadData();
                } else if (response.data.code === "ZKCSAI0002") {
                    toast.warning(t("TEM10"), {
                        position: "top-right",
                    });
                    setConfirm(false);
                    loadData();
                } else {
                    toast.error(t("TEM11"), {
                        position: "top-right",
                    });
                    setConfirm(false);
                    loadData();
                }
            } else {
                toast.error(t("TEM11"), {
                    position: "top-right",
                });
                setConfirm(false);
                loadData();
            }
        })
    }

    const handleOpen = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setOpen(true);
    };

    const handleConfirmOpen = (imageUrl) => {
        setConfirm(true);
    };

    const handleChangePage = (newPage) => {
        setSelectedValue("");
        setPageOperation({ ...pageOperation, page: newPage });
        setcallApiFilter(true);
    };

    const handleChangeRowsPerPage = (pageSize) => {
        setSelectedValue("");
        setPageOperation({ ...pageOperation, page: 0, rowsPerPage: pageSize });
        setcallApiFilter(true);
    };

    const handleSelectedRows = (data) => {
        console.log("data", data);
    };

    const handleRefresh = () => {
        setSelectedValue("");
        setPageOperation({ page: 0, rowsPerPage: 10 });
        loadData();
    }


    var userFilter = "";
    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setSelectedValue(value)

        userFilter = value;
        loadData();
        setPageOperation({ ...pageOperation, page: 0 });
    };

    const actions = () => (
        <Box item style={{ display: "inherit" }}>
            <FormControl sx={{ width: 170 }}>

                <InputLabel htmlFor="select-placeholder">
                    {selectedValue === '' ? 'Today' : ""}
                </InputLabel>

                <Select style={{ backgroundColor: '#f5f7f8', marginRight: '13px', height: '40px', marginTop: '5px' }}
                    value={selectedValue}
                    onChange={handleChange}
                    inputProps={{ id: 'select-placeholder' }}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Tooltip title={t("USER2")} arrow>
                <Refresh id="refresh-tempAuditList"
                    width={"40px"}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => {
                        handleRefresh();
                    }}
                />
            </Tooltip>
        </Box>
    );

    return (
        <Grid >
            <RightViewLayout
                title={t("TEM1")}
                actions={actions}
                type="table"
            >
                <Grid container margin='auto' paddingLeft={'0px'} overflow={"initial"} display="flex" >
                    <DataTable
                        columns={columns}
                        rows={rows}
                        onSelection={(data) => handleSelectedRows(data)}
                        page={pageOperation.page}
                        totalRecords={totalRecords}
                        rowsPerPage={pageOperation.rowsPerPage}
                        onPageChange={(pn) => handleChangePage(pn)}
                        onPageSizeChange={(ps) => handleChangeRowsPerPage(ps)}
                        disableRowSelectionOnClick
                    />
                </Grid>
            </RightViewLayout>
            <Dialog open={open} onClose={handleClose} maxWidth="md" className={classes.customClass}
                style={{ backdropFilter: 'blur(5px)' }}>
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant='subtitle2' className={classes.btnStyle} >{t("TEM12")}</Typography>
                        <CloseIcon onClick={handleClose} className={classes.btnStyle} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={selectedImageUrl} height={'150px'} alt="Selected Template" draggable="false" />
                </DialogContent>
            </Dialog>
            <Dialog open={confirm} onClose={handleClose} maxWidth="md">
                <DialogTitle> <Typography variant='subtitle2'>{t("TEM13")}</Typography></DialogTitle>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} padding={"10px"} justifyContent={"center"}>
                    <Box display="flex" justifyContent="center">
                        <Button variant="outlined" color="primary" onClick={handleClose} sx={{ marginRight: 2 }}> {t("TEM14")}  </Button>
                        <Button variant="contained" color="primary" onClick={() => handleDelete(id)}>  {t("TEM15")} </Button>
                    </Box>
                </Grid>
            </Dialog>
        </Grid>

    );
}
