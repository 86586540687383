import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Slide,
  Tooltip
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ZKBIOPartnerIconWithoutName } from "../../assets/images/ZKDIGIMAXLOGO-NEW-1.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/images/hamburger.svg";

import { REACT_HOME_SCREEN } from "../../constants/ReactEndPoints";
import menuItemsAdmin from './SideBarItemAdmin';
import customerItems from './SideBarItemCustomer';
import SideBarItemGraphicD from './SideBarItemGraphicD';
import menuItemsOwner from './SideBarItemHQ';
import SideBarItems from './SideBarItems';


import CheckIcon from '@mui/icons-material/Check';
import { IconFlagES, IconFlagUS } from "material-ui-flags";
import { useTranslation } from "react-i18next";
import UserService from "../../services/UserService";
import BrowserLanguage from "../../util/BrowserLanguage";
import sidebarContext from "../../util/sidebarContext";


export default function SideBar(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const [contextMenu, setContextMenu] = React.useState(true);
  const { mode, setMode } = useContext(sidebarContext);
  const [Selected, setSelected] = React.useState(0);

  const [selectedMenu, setSelectedMenu] = React.useState("");
  const [selectedSubMenu, setSelectedSubMenu] = React.useState("");

  const role = localStorage.getItem("ROLE_NAME")


  //   {
  //     id: "8",
  //     name: "Home",
  //     link: "/HomeScreen",
  //     Icon: HomeIcon,
  //     Icon1: HomeIcon_white,
  //   },
  //   {
  //     id: "9",
  //     name: "My Order",
  //     link: "/MyOrders",
  //     Icon: myorderIcon_w,
  //     Icon1: myorderIcon,
  //   },
  // ];

  useEffect(() => {
    const parentItem = SideBarItems.find((item) => {
      if (item.items) {
        return item.items.some(
          (childItem) => childItem.link === location.pathname
        );
      }
      return item.link === location.pathname;
    });

    const childItem = SideBarItems.reduce((foundChild, parentItem) => {
      if (parentItem.items) {
        const child = parentItem.items.find((childItem) => childItem.link === location.pathname);
        if (child) {
          return child;
        }
      }
      return foundChild;
    }, null);

    setSelectedMenu(parentItem?.name || null);
    setSelectedSubMenu(childItem?.name || null);
  }, [location.pathname]);

  const handleClickIcon = () => {
    setSelectedMenu('Dashboard')
    setSelectedSubMenu('');
    navigate(REACT_HOME_SCREEN);
  }
  const handleMenu = (menu) => {
    setContextMenu(true)
    if (!mode || menu.name === "Dashboard") {
      if (selectedMenu === menu.name) {
        setSelectedMenu("");
        // selectedSubMenu("");
      } else {
        setSelectedMenu(menu.name);
        if (menu.link) navigate(menu.link);
      }
    }
    setSelectedMenu(menu.name);
    if (mode) toggleSidebar(!mode);
  };


  const handleMenu1 = (menu) => {
    if (!mode || menu.name === "Dashboard") {
      if (selectedMenu === menu.name) {
        setSelectedMenu("");
      } else {
        setSelectedMenu(menu.name);
        if (menu.link) navigate(menu.link);
      }
    }
    setSelectedMenu(menu.name);
    if (menu.name === 'Languages' || menu.name === 'Bahasa') {
      setAnchorEl(menu);
    } else {
      setAnchorEl(null);
    }
    if (menu.name === 'Profile' || menu.name === 'Profil') {
      setAnchorProfileEl(menu);
    } else {
      setAnchorProfileEl(null);
    }

    if (mode) toggleSidebar(!mode);
  };

  const toggleSidebar = (toggle) => {
    // props.exapnded(toggle);
    setMode(toggle);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorProfileEl, setAnchorProfileEl] = useState(null);
  const openProfile = Boolean(anchorProfileEl)

  const handleClose = () => {
    setSelectedMenu("");
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setSelectedMenu("");
    setAnchorProfileEl(null)
  };

  const handleProfile = () => {
    setContextMenu(true)
    navigate('/profileDetails')
    setAnchorProfileEl(null)
  }
  const handleEditProfile = () => {
    navigate('/EditProfileDetails')
    setAnchorProfileEl(null)
  }

  const handleLogout = () => {
    UserService.logout().then((data) => {
      if (data.data.code === "ZKCSAI0001") {
        localStorage.removeItem('companyCode')
        localStorage.removeItem('companyName')
        localStorage.removeItem('USER_TOKEN')
        localStorage.removeItem('ROLE_NAME')
        localStorage.removeItem('USER_NAME')
        localStorage.removeItem('FIRST_NAME')
        localStorage.removeItem('REFRESH_TOKEN')
        localStorage.removeItem('companyId')
        localStorage.removeItem('COUNTRY');
        navigate('/');
      }
    });
  }

  const handleUpdatePassword = () => {
    setContextMenu(true)
    navigate('/UpdatePassword')
    setAnchorProfileEl(null)
  }


  const handleSubMenu = (menu) => {
    setSelectedSubMenu(menu.name);
    if (menu.link) navigate(menu.link);
  };

  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
    if (event[0] === undefined) {
      BrowserLanguage.getDefaultLanguage();
    } else {
      localStorage.setItem("i18nextLng", event);
      BrowserLanguage.setLanguage(event);
      i18n.changeLanguage(event);
      setSelectedLang(event);
    }
    window.location.reload(false);
  };

  useEffect(() => {
    const currentLanguage = localStorage.getItem('i18nextLng')
    setSelectedLang(currentLanguage)
  }, [])

  return (
    < Grid style={{ display: 'flex', position: 'relative' }}>

      <Grid container width={'20%'}
        style={{ padding: '10px 0px 10px 10px' }}
      >
        {contextMenu ? (
          <Box sx={{
            display: 'flex', justifyContent: 'left',
            position: 'fixed', width: '100%',  height: '50px', zIndex: '9999'
          }}>
            <Box sx={{ backgroundColor: '#fff' }}>
              <IconButton id='side-menuBarClose'
                style={{
                  alignSelf: 'center', borderRadius: '8px', backgroundColor: '#fff', top: '5px',
                  height: '40px', width: '40px', position: 'absolute', left: '10px', boxShadow: '#d7e8f48f 0.5px 1px 8px 3px',
                }}
                onClick={() => setContextMenu(!contextMenu)}
                disableRipple
              >
                <HamburgerIcon />
              </IconButton>
              <Grid onClick={() => navigate(REACT_HOME_SCREEN)}
                in={contextMenu}
              // style={{ transformOrigin: '0 0 0' }}
              // {...(contextMenu ? { timeout: 1000 } : {})}
              >
                <ZKBIOPartnerIconWithoutName onClick={() => handleClickIcon()} style={{
                  height: '50px',
                  width: '140px',
                  position: 'absolute',
                  marginLeft: '63px',
                  cursor: 'pointer'
                }} />
              </Grid>
            </Box>
          </Box>
        )
          : (
            <Box sx={{
              display: 'flex', justifyContent: 'left',
              position: 'fixed', width: '100%',  height: '50px', zIndex: '9999'
            }}>
              <IconButton id='side-menuBarOpen'
                onClick={() => setContextMenu(!contextMenu)}
                disableRipple
                style={{
                  alignSelf: 'center', borderRadius: '8px', backgroundColor: '#fff', top: '5px',
                  height: '40px', width: '40px', position: 'absolute', left: '10px', boxShadow: '#d7e8f48f 0.5px 1px 8px 3px',
                }}
              >
                <HamburgerIcon />
              </IconButton>
              <Slide direction="right" in={!contextMenu}
                style={{ transformOrigin: '0 0 0' }}
                {...(!contextMenu ? { timeout: 2000 } : {})}
              >
                <ZKBIOPartnerIconWithoutName onClick={() => handleClickIcon()} style={{
                  height: '50px',
                  width: '140px',
                  position: 'absolute',
                  marginLeft: '63px',
                  cursor: 'pointer'
                }} />
              </Slide>
            </Box>
          )}
        <Slide direction="right" mountOnEnter unmountOnExit
          in={!contextMenu}
          style={{ transformOrigin: '0 0 0' }}
          {...(!contextMenu ? { timeout: 2000 } : {})}
        >
          <Grid
            style={{
              display: "flex",
              padding: "0px 0px",
              // backgroundColor: "#FFFFFF",
              position: "relative",
              boxShadow: '0px 7px 12px 0px #deeaf4',
              borderRadius: '7px',
              border: '0.5px solid #3F84C3',
              backgroundColor:'#fff'
            }}
          >
            <Grid
              style={{
                display: contextMenu ? "none" : "flex",
                flexDirection: "column",
                width: contextMenu ? "0px" : "250px",
              }}
            >
              <List
                sx={{ width: "100%", maxWidth: 360, paddingTop:'50px' }}
                component="nav"
              >
                {role === "OWNER" ? (
                  menuItemsOwner.map((item, index) => (
                    <ListItemButton
                      key={item.id}
                      onClick={() => handleMenu(item)}
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: "7px",
                        padding: "5px",
                        borderRadius: "7px",
                        backgroundColor: selectedMenu === item.name || location.pathname === item.link ? "#1487CA" : ""
                      }}
                    >
                      <ListItemIcon style={{ paddingLeft: "16px" }}>
                        {selectedMenu === item.name || location.pathname === item.link ? (<item.Icon />) : (<item.Icon1 />)}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          fontWeight: selectedMenu === item.name,
                          color: selectedMenu === item.name || location.pathname === item.link ? "#fff" : "#A2A3A3",
                          fontSize: "14px",
                          width: "max-content",
                          paddingRight: "10px",
                        }}
                      />
                    </ListItemButton>
                  ))
                ) : role === "ADMIN" ? (
                  menuItemsAdmin.map((item, index) => (
                    <ListItemButton
                      key={item.id}
                      onClick={() => handleMenu(item)}
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: "7px",
                        padding: "5px",
                        borderRadius: "7px",
                        backgroundColor: selectedMenu === item.name || location.pathname === item.link ? "#1487CA" : ""
                      }}
                    >
                      <ListItemIcon style={{ paddingLeft: "16px" }}>
                        {selectedMenu === item.name || location.pathname === item.link ? (<item.Icon />) : (<item.Icon1 />)}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          fontWeight: selectedMenu === item.name,
                          color: selectedMenu === item.name || location.pathname === item.link ? "#fff" : "#A2A3A3",
                          fontSize: "14px",
                          width: "max-content",
                          paddingRight: "10px",
                        }}
                      />
                    </ListItemButton>
                  ))
                ) : role === "GRAPHIC DESIGNER" ? (
                  SideBarItemGraphicD.map((item, index) => (
                    <ListItemButton
                      key={item.id}
                      onClick={() => handleMenu(item)}
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: "7px",
                        padding: "5px",
                        borderRadius: "5px",
                        backgroundColor: selectedMenu === item.name || location.pathname === item.link ? "#1487CA" : ""
                      }}
                    >
                      <ListItemIcon style={{ paddingLeft: "16px" }}>
                        {selectedMenu === item.name ||
                          location.pathname === item.link ? (
                          <item.Icon />
                        ) : (
                          <item.Icon1 />
                        )}
                      </ListItemIcon>
                      <Tooltip title={item.name === "" ? "" : ""}>
                        <ListItemText
                          primary={item.name}
                          primaryTypographyProps={{
                            fontWeight: selectedMenu === item.name,
                            color: selectedMenu === item.name || location.pathname === item.link ? "#fff" : "#A2A3A3",
                            fontSize: "14px",
                            width: "max-content",
                            paddingRight: "10px",
                          }}
                        />
                      </Tooltip>
                    </ListItemButton>
                  ))
                ) : role === "CUSTOMER" ? (
                  customerItems.map((item, index) => (
                    <ListItemButton
                      key={item.id}
                      onClick={() => handleMenu(item)}
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: "7px",
                        padding: "5px",
                        borderRadius: "5px",
                        backgroundColor: selectedMenu === item.name || location.pathname === item.link ? "#1487CA" : ""
                      }}
                    >
                      <ListItemIcon style={{ paddingLeft: "16px" }}>
                        {selectedMenu === item.name ||
                          location.pathname === item.link ? (
                          <item.Icon />
                        ) : (
                          <item.Icon1 />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          fontWeight: selectedMenu === item.name,
                          color: selectedMenu === item.name || location.pathname === item.link ? "#fff" : "#A2A3A3",
                          fontSize: "14px",
                          width: "max-content",
                          paddingRight: "10px",
                        }}
                      />
                    </ListItemButton>
                  ))
                ) : <></>
                }
              </List>
              <List
                sx={{ width: "100%", maxWidth: 250, position: "absolute", bottom: "0", paddingBottom: "2%" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {(role === "OWNER" || role === "ADMIN" || role === "GRAPHIC DESIGNER" || role === "CUSTOMER") ? (
                  SideBarItems.map((item, index) => (
                    <ListItemButton
                      onClick={() => handleMenu1(item)}
                      style={{
                        width: "90%",
                        margin: "auto",
                        marginTop: "7px",
                        padding: "5px",
                        borderRadius: "5px",
                        backgroundColor: selectedMenu === item.name || location.pathname === item.link ? "#1487CA" : ""
                      }}
                    >
                      <ListItemIcon style={{ paddingLeft: "13px" }}>
                        {selectedMenu === item.name ||
                          location.pathname === item.link ? (
                          <item.Icon />
                        ) : (
                          <item.Icon1 />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          fontWeight: selectedMenu === item.name,
                          color: selectedMenu === item.name || location.pathname === item.link ? "#fff" : "#A2A3A3",
                          fontSize: "14px",
                          width: "max-content",
                          paddingRight: "10px",
                        }}
                      />
                      {item.name !== "Logout" && item.name !== "Keluar" && (
                        <ListItemIcon style={{ paddingLeft: "13px", justifyContent: 'flex-end' }}>
                          {selectedMenu === item.name || location.pathname === item.link ?
                            (
                              <ExpandMoreIcon style={{ color: '#dcdcdc' }} />
                            ) : (
                              <ChevronRightIcon style={{ color: '#dcdcdc' }} />
                            )}
                        </ListItemIcon>
                      )}
                    </ListItemButton>
                  ))) : ''}
              </List>

              {(selectedMenu === 'Languages' || selectedMenu === 'Bahasa') && (
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  style={{ left: '250px' }}
                >
                  <MenuItem
                    value={'en'}
                    key={'en'}
                    onClick={() => {
                      handleMenuItemClick('en');
                    }}
                    sx={[
                      (theme) => ({
                        '&:hover': {
                          background: '#226BAB1A',
                        },
                      }),
                    ]}
                  >
                    {' '}
                    <IconFlagUS className='lang-flag' />
                    &nbsp; {t('i18nLabelEnglishFlag')}
                    &nbsp;
                    {selectedLang === 'en' && <CheckIcon style={{ color: 'green' }} />}
                  </MenuItem>
                  <MenuItem
                    value={'idn'}
                    key={'idn'}
                    onClick={() => {
                      handleMenuItemClick('idn');
                    }}
                    sx={[
                      (theme) => ({
                        '&:hover': {
                          background: '#226BAB1A',
                        },
                      }),
                    ]}
                  >
                    {' '}
                    <IconFlagES className='lang-flag' />
                    &nbsp; {t('i18nLabelIndonesiaFlag')}&nbsp;
                    {selectedLang === 'idn' && <CheckIcon style={{ color: 'green' }} />}
                  </MenuItem>
                </Menu>
              )}

              {(selectedMenu === 'Profile' || selectedMenu === 'Profil') && (
                <Menu
                  id="basic-menu1"
                  anchorEl={anchorProfileEl}
                  open={openProfile}
                  onClose={handleClose1}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  style={{ left: '250px' }}
                >
                  <MenuItem onClick={handleProfile}>{t("SID01")}</MenuItem>
                  {/* {role !== "OWNER" ? (
                    <MenuItem onClick={handleEditProfile}>Edit Profile</MenuItem>
                  ) : (null)} */}
                  {role !== "OWNER" ? (
                    <MenuItem onClick={handleUpdatePassword} >{t("SID02")}</MenuItem>
                  ) : (null)}
                  <MenuItem onClick={handleLogout} >{t("SID03")}</MenuItem>
                </Menu>
              )}
            </Grid>
          </Grid>
        </Slide>
      </Grid>
    </Grid>
  );
}
