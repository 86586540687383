
import { ReactComponent as Man_white } from "../../assets/images/BannerMan1.svg";
import { ReactComponent as ManIcon } from "../../assets/images/BannerMan2.svg";
import { ReactComponent as List_white } from "../../assets/images/List.svg";
import { ReactComponent as ListIcon } from "../../assets/images/Union 413.svg";
import { ReactComponent as UserIcon } from "../../assets/images/User(2).svg";
import { ReactComponent as UserIcon_white } from "../../assets/images/Users Icon 1.svg";
import { ReactComponent as HomeIcon_white } from "../../assets/images/home (1).svg";
import { ReactComponent as HomeIcon } from "../../assets/images/home (2).svg";
import { ReactComponent as Tag_white } from "../../assets/images/TagIcon_W.svg";
import { ReactComponent as TagIcon } from "../../assets/images/TagIcon.svg";
import BrowserLanguage from "../../util/BrowserLanguage";

let language = BrowserLanguage.getDefaultLanguage();
const dataEn = [
  {
    id: "8",
    name: "Home",
    link: "/HomeScreen",
    Icon: HomeIcon,
    Icon1: HomeIcon_white,
  },

  {
    id: "0",
    name: "Users",
    link: "/UserList",
    Icon: UserIcon,
    Icon1: UserIcon_white,
  },

  {
    id: "14",
    name: "Template List",
    link: "/TemplateAuditList",
    Icon: List_white,
    Icon1: ListIcon,
  },

  {
    id: "15",
    name: "Banner Management",
    link: "/BannerManagement",
    Icon: Man_white,
    Icon1: ManIcon,
  },
  {
    id: "16",
    name: "Tags",
    link: "/Tags",
    Icon: Tag_white,
    Icon1: TagIcon,
  }
];

const dataIds = [
  {
    id: "8",
    name: "Rumah",
    link: "/HomeScreen",
    Icon: HomeIcon,
    Icon1: HomeIcon_white,
  },

  {
    id: "0",
    name: "Pengguna",
    link: "/UserList",
    Icon: UserIcon,
    Icon1: UserIcon_white,
  },

  {
    id: "14",
    name: "Daftar Templat",
    link: "/TemplateAuditList",
    Icon: List_white,
    Icon1: ListIcon,
  },

  {
    id: "15",
    name: "Manajemen Spanduk",
    link: "/BannerManagement",
    Icon: Man_white,
    Icon1: ManIcon,
  },
  {
    id: "16",
    name: "Tag",
    link: "/Tags",
    Icon: Man_white,
    Icon1: ManIcon,
  }
]

export default language === 'en' ? dataEn : language === 'idn' ? dataIds : '';


