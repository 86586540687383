

import BrowserLanguage from "../../util/BrowserLanguage";


import { ReactComponent as HomeIcon_white } from "../../assets/images/home (1).svg";
import { ReactComponent as HomeIcon } from "../../assets/images/home (2).svg";
import { ReactComponent as myorderIcon } from "../../assets/images/myorder.svg";
import { ReactComponent as myorderIcon_w } from "../../assets/images/myorder_w.svg";

let language = BrowserLanguage.getDefaultLanguage();
const dataEn = [
  {
    id: "8",
    name: "Home",
    link: "/HomeScreen",
    Icon: HomeIcon,
    Icon1: HomeIcon_white,
  },
  {
    id: "9",
    name: "My Order",
    link: "/MyOrders",
    Icon: myorderIcon_w,
    Icon1: myorderIcon,
  },
];

const dataIds = [
  {
    id: "8",
    name: "Rumah",
    link: "/HomeScreen",
    Icon: HomeIcon,
    Icon1: HomeIcon_white,
  },
  {
    id: "9",
    name: "Pesananku",
    link: "/MyOrders",
    Icon: myorderIcon_w,
    Icon1: myorderIcon,
  },
]

export default language === 'en' ? dataEn : language === 'idn' ? dataIds : '';


