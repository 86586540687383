import {
    AppBar,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from '@mui/icons-material/Edit';
import { createTheme } from "@mui/material/styles";
import { Carousel } from "@trendyol-js/react-carousel";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import goverment from "../../assets/industries/Group 10636.svg";
import fb from "../../assets/industries/Union 367.svg";
import retail from "../../assets/industries/Union 368.svg";
import fashion from "../../assets/industries/Union 369.svg";
import beauty from "../../assets/industries/Union 370.svg";
import finance from "../../assets/industries/Union 371.svg";
import automative from "../../assets/industries/Union 373.svg";
import health from "../../assets/industries/Union 374.svg";
import hospitality from "../../assets/industries/Union 376.svg";
import realestate from "../../assets/industries/Union 378.svg";
import telecomunication from "../../assets/industries/Union 379.svg";
import tech from "../../assets/industries/Union 380.svg";
import { ReactComponent as PremiumIcon } from "../../assets/industries/premiumIcon.svg";
import { REACT_INDUSTRIES_LAYOUT, REACT_PREVIEW_IMAGE } from "../../constants/ReactEndPoints";
import { RecentlyViewedTemp, getAdvertApprovedList, getAllDefultTags, getTemplatesByCategory, getTemplatesBySearch, mostlyViewd, mostlyViewedTemplate, recentlyViewed, recentlyViewedTemplate } from "../../services/AdvertService";
import { getBannerList } from "../../services/BannerService";
import Loader from "../Loader";
import Search from "../Search";
import { makeStyles } from "@material-ui/core";
import { getAllTags } from "../../services/TagService";
import jwt_decode from "jwt-decode";
import UserService from "../../services/UserService";
import { mostlyOrderedTemplate } from "../../services/Orderservice";


export default function Header() {

    const [registerOpen, setRegisterOpen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const userName = localStorage.getItem("USER_NAME");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await UserService.getUser(userName);
                if (res.data.code === "UASI0033") {
                    const fullName = res.data.data.firstName + " " + res.data.data.lastName;
                    setFirstName(fullName);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [userName]);
    const lang = localStorage.getItem("i18nextLng");
    const [loading, setLoading] = useState(false);


    const useStyles = makeStyles((theme) => ({
        customClass: {
            '& .MuiDialog-paper': {
                backgroundColor: 'transparent',
            },
        },
        btnStyle: {
            color: '#fff',
            cursor: 'pointer'
        }
    }));
    const classes = useStyles();
    const handleRegisterOpen = () => {
        setRegisterOpen(true);
    };

    const handleRegisterClose = () => {
        setRegisterOpen(false);
    };
    const navigate = useNavigate();
    const theme = createTheme({
        palette: {
            primary: {
                main: "#0971f1",
                darker: "#053e85",
            },
            neutral: {
                main: "#FFFFF",
                contrastText: "black",
            },
        },
    });


    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        adaptiveHeight: true
    };


    useEffect(() => {
        setLoading(true);
        loadData();
        loadBannerList();
    }, [])

    useEffect(() => {
        loadAllTag();
        handleSearchIconClick();
    }, [])



    const [image, setImage] = useState("");
    const [categoryByName, setCategoryByName] = useState("");

    const templatesImages = [image];
    const flattenedArray = templatesImages.flat();
    console.log("flattenedArray", flattenedArray)

    const categoryImages = [categoryByName];
    const searchedCategoryImages = categoryImages.flat();
    console.log("searchedCategoryImages", searchedCategoryImages);


    const country = localStorage.getItem("COUNTRY");
    const loadData = () => {
        getAdvertApprovedList(country).then(
            (res) => {
                if (res.data.code === "ZKCSAE0001") {
                    setImage(res.data.data)
                    setLoading(false);
                    if (res.data.code === "ZKCSAE0001") {
                    } else {
                        setImage([]);
                    }
                }
            }
        );

    }

    const templates = [
        "New Template",
        "Menu Board",
        "Events & Promotions",
        "Recently Viewed",
        "Most Viewed"
    ];

    const categories = [
        { name: t("LAN3"), imageUrl: goverment },
        { name: t("LAN4"), imageUrl: fb },
        { name: t("LAN5"), imageUrl: retail },
        { name: t("LAN6"), imageUrl: fashion },
        { name: t("LAN7"), imageUrl: beauty },
        { name: t("LAN8"), imageUrl: finance },
        { name: t("LAN9"), imageUrl: automative },
        { name: t("LAN10"), imageUrl: health },
        { name: t("LAN11"), imageUrl: hospitality },
        { name: t("LAN12"), imageUrl: realestate },
        { name: t("LAN13"), imageUrl: telecomunication },
        { name: t("LAN14"), imageUrl: tech },
    ];

    const columnWidths = ["80px", "80px", "100px", "80px"];
    const columnTemplate = columnWidths.map((width) => `${width} `).join("");

    const [selectedCategory, setSelectedCategory] = useState(null);

    const [open, setOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [selectedCategoryy, setSelectedCategoryy] = useState("");

    const editCustomer = localStorage.getItem("ROLE_NAME") === "CUSTOMER";

    const userId = localStorage.getItem('USER_ID')

    useEffect(() => {
        loadViewed();
        loadmostlyViewed();
        loadmostlyOrdered();
    }, [])

    const handleOpen = (imageUrl, id, category) => {
        setSelectedImageUrl(imageUrl);
        setSelectedId(id)
        setSelectedCategoryy(category)
        setOpen(true);

        const payload = {
            userId: userId,
            advertId: id
        }
        recentlyViewed(payload).then((res) => {
            if (res?.data?.code === "ZKCSAI0001") {
                console.log("recentlyViewed", res.data.data)
            }
        })
        if (role === 'CUSTOMER') {
            handleMostViewdTemp(id);
        }
    };



    const handleMostViewdTemp = (id) => {
        mostlyViewd(id).then((res) => {
            console.log("res", "res")
            if (res?.data?.code === "ZKCSAE0000") {
                console.log("mostlyViewd", res.data)
            }
        })
    }

    const [recentlyViewedTemp, setRecentlyViewedTemp] = useState([]);

    const loadViewed = () => {
        recentlyViewedTemplate(userId).then((res) => {
            if (res?.data?.code === "ZKCSAI0001") {
                setRecentlyViewedTemp(res?.data?.data);
            }
            else {
                setRecentlyViewedTemp([]);
            }
        });
    }


    const [mostlyViewedTemp, setMostlyViewedTemp] = useState([]);

    const loadmostlyViewed = () => {
        mostlyViewedTemplate().then((res) => {
            if (res?.data?.code === "ZKCSAI0001") {
                setMostlyViewedTemp(res?.data?.data);
            }
            else {
                setMostlyViewedTemp([]);
            }
        });
    }

    const [mostlyOrderdTemp, setMostlyOrderedTemp] = useState([]);

    const loadmostlyOrdered = () => {
        mostlyOrderedTemplate().then((res) => {
            if (res?.data?.code === "ZKCSAI0001") {
                setMostlyOrderedTemp(res?.data?.data);
            }
            else {
                setMostlyOrderedTemp([]);
            }
        });
    }



    const handleEdit = () => {
        const id = selectedId;
        const category = selectedCategoryy;
        navigate(REACT_PREVIEW_IMAGE, { state: { id, category } });
        setSelectedImageUrl(selectedImageUrl);
        setOpen(true);
    };
    const roleId = localStorage.getItem("ROLE_NAME");
    const handleClose = () => {
        //recently-viewed
        if (localStorage.getItem('ROLE_NAME') === 'CUSTOMER') {

            recentlyViewedTemplate(userId).then((res) => {
                if (res?.data?.code === "ZKCSAI0001") {
                    setRecentlyViewedTemp(res?.data?.data);
                }
                else {
                    setRecentlyViewedTemp([]);
                }
            });

            //mostly-viewed
            mostlyViewedTemplate().then((res) => {
                if (res?.data?.code === "ZKCSAI0001") {
                    setMostlyViewedTemp(res?.data?.data);
                }
                else {
                    setMostlyViewedTemp([]);
                }
            });
        }
        setOpen(false);
    };

    const [loginOpen, setLoginOpen] = useState(false);

    const handleLoginClose = () => {
        setLoginOpen(false);
    };

    const role = localStorage.getItem("ROLE_NAME")

    const getImageUrlCount = (template) => {
        return flattenedArray.filter(
            (item) => item.template === template && (!selectedCategory || item.category === selectedCategory)
        ).length;
    };

    const getTemplateName = (template) => {
        if (lang === 'idn') {
            if (template === "New Template") {
                return t("TEMPLATES")
            } else if (template === "Menu Board") {
                return t("TEMPLATES1")
            } else if (template === "Events & Promotions") {
                return t("TEMPLATES2")
            }
        } else {
            return template;
        }

    };

    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchIconClick = (searchedVal) => {
        if (lang === 'idn') {
            if (searchedVal === 'Pemerintah') {
                searchedVal = 'Government';
            } else if (searchedVal === 'makanan dan minuman') {
                searchedVal = 'F&B';
            } else if (searchedVal === 'Pengecer') {
                searchedVal = 'Retail';
            } else if (searchedVal === 'Mode') {
                searchedVal = 'Fashion';
            } else if (searchedVal === 'Kecantikan') {
                searchedVal = 'Beauty';
            } else if (searchedVal === 'Keuangan') {
                searchedVal = 'Finance';
            } else if (searchedVal === 'Otomotif') {
                searchedVal = 'Automotive';
            } else if (searchedVal === 'Kesehatan') {
                searchedVal = 'Health';
            } else if (searchedVal === 'Keramahan') {
                searchedVal = 'Hospitality';
            } else if (searchedVal === 'Perumahan') {
                searchedVal = 'Real Estate';
            } else if (searchedVal === 'Telekomunikasi') {
                searchedVal = 'Telecommunication';
            } else if (searchedVal === 'Teknologi') {
                searchedVal = 'Tech';
            }
        }

        const country = localStorage.getItem("COUNTRY");
        if (searchedVal) {
            const trimmedVal = searchedVal.trim();
            if (trimmedVal === '') {
                setCategoryByName([]);
            } else {
                getTemplatesBySearch(trimmedVal, country).then(
                    (res) => {
                        if (res?.data?.code === "ZKCSAE0001") {
                            setCategoryByName(res?.data?.data);
                        }
                    }
                );
            }
        } else {
            setCategoryByName([]);
        }
    };

    const handleSearchClick = (categoryName) => {
        if (lang === 'idn') {
            if (categoryName === 'Pemerintah') {
                categoryName = 'Government';
            } else if (categoryName === 'makanan dan minuman') {
                categoryName = 'F&B';
            } else if (categoryName === 'Pengecer') {
                categoryName = 'Retail';
            } else if (categoryName === 'Mode') {
                categoryName = 'Fashion';
            } else if (categoryName === 'Kecantikan') {
                categoryName = 'Beauty';
            } else if (categoryName === 'Keuangan') {
                categoryName = 'Finance';
            } else if (categoryName === 'Otomotif') {
                categoryName = 'Automotive';
            } else if (categoryName === 'Kesehatan') {
                categoryName = 'Health';
            } else if (categoryName === 'Keramahan') {
                categoryName = 'Hospitality';
            } else if (categoryName === 'Perumahan') {
                categoryName = 'Real Estate';
            } else if (categoryName === 'Telekomunikasi') {
                categoryName = 'Telecommunication';
            } else if (categoryName === 'Teknologi') {
                categoryName = 'Tech';
            }
        }
        navigate(REACT_INDUSTRIES_LAYOUT, { state: { categoryName: categoryName } })
        setSelectedCategory(categoryName);
    };

    const [bannerList, setBannerList] = useState("");
    const bannerImages = [bannerList];
    const bannerlist = bannerImages.flat();

    const loadBannerList = () => {
        getBannerList().then((res) => {
            if (res?.data?.code === "ZKCSAE0001") {
                setBannerList(res?.data?.data)
            }
        }
        );
    }


    const [allTag, setAllTag] = useState([])

    const loadAllTag = () => {
        getAllDefultTags().then((res) => {
            if (res?.data?.code === "ZKCSAI0001") {
                setAllTag(res?.data?.data.tagsList);
            }
        });
    }


    return (
        <Grid container width="100%" margin={"auto"} overflow={"hidden"} >
            <ThemeProvider theme={theme} >
                <AppBar elevation={0} position="static" color="neutral" sx={{ backgroundColor: 'transparent' }} style={{
                    top: "0px",
                    left: "0px",
                    height: "50px",
                    // background: "#F7FBFE 0% 0% no-repeat padding-box",
                    opacity: "1",
                    marginTop: '40px',
                }}>
                    <Toolbar>
                        <Typography sx={{ color: '#474B4F', opacity: '1', font: 'normal normal 600 18px/29px Roboto', }}> {t("LAN15")} {firstName}, </Typography>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
                        <Grid marginRight={'1%'} sx={{ backgroundColor: '#fff' }}>
                            <Box item >
                                <Search
                                    onSearch={(e) => handleSearchIconClick(e)}
                                    clearSearch={(e) => handleSearchIconClick(e)}
                                />
                            </Box>
                        </Grid>
                    </Toolbar>
                </AppBar>
                {loading ?
                    <>
                        <Loader />
                    </>
                    :
                    <Grid style={{
                        width: "100%",
                        //  backgroundColor: "#F7FBFE",
                        justifyContent: "center", overflowX: "hidden", overflowY: "hidden"
                    }} margin="auto">
                        {role === "CUSTOMER" ? (
                            <div style={{ paddingTop: '30px', marginBottom: '20px' }}>
                                <Slider {...settings}>
                                    {bannerlist.map((item, index) => (
                                        <div key={index}>
                                            <img
                                                style={{ width: "100%", marginBottom: "5px", borderRadius: '10px' }}
                                                src={item.imageUrl} draggable="false"
                                                alt="Banner" />
                                        </div>))
                                    }
                                </Slider>
                            </div>
                        ) : (null)}

                        <Grid width="100%" margin="auto" padding={'0px 0px 20px 0px'}>
                            <Grid style={{ display: "flex", justifyContent: 'center', alignItems: 'center', paddingTop: '10px', marginBottom: '10px', }}>
                                <Carousel dynamic={true} leftArrow={false} rightArrow={false}
                                    show={12}
                                    slide={3}
                                    swiping={true}
                                    pageCount={5}
                                >
                                    {allTag.map((item) => (
                                        <Grid key={item.id}
                                            onClick={() => handleSearchClick(item.tag)}
                                            style={{ opacity: "1", cursor: "pointer", textAlign: "center", alignContent: "center", padding: "0px 2px", }}
                                        >
                                            <Typography variant="body2" className="categoryStyle">
                                                <Tooltip title={item.tag.length > 10 ? item.tag : ''}>
                                                    <span>
                                                        {item.tag.substring(0, 10)}
                                                    </span>
                                                </Tooltip>
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Carousel>
                            </Grid>
                        </Grid>

                        {searchedCategoryImages.length < 1 ? (
                            <>
                                <Grid >
                                    {templates.map((template, index) => {
                                        const imageUrlCount = getImageUrlCount(template);
                                        if (imageUrlCount < 1) {
                                            return null;
                                        }
                                        if (template === null) {
                                            return null;
                                        }
                                        return (
                                            <Box key={index} sx={{ marginBottom: '10px' }}>
                                                <Grid container display={'flex'} >
                                                    <Typography className="tempHeading1"> {getTemplateName(template)}</Typography> &nbsp;
                                                    {/* <Typography className="tempHeading2">See All</Typography> */}
                                                </Grid>
                                                <Carousel dynamic={true} leftArrow={false}
                                                    show={4}
                                                    slide={3}
                                                    swiping={true}
                                                    pageCount={5}
                                                >
                                                    {flattenedArray
                                                        .filter((item) => template === "" || template === item.template)
                                                        .filter((item) => selectedCategory === null || selectedCategory === item.category)
                                                        .map((item, index) => (
                                                            <Grid key={index}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    textAlign: "center",
                                                                    top: "982px",
                                                                    width: "348px",
                                                                    height: "289px",
                                                                    opacity: "1",
                                                                }}
                                                            >
                                                                {item?.advertType === 'premium' ?
                                                                    (
                                                                        <div class="Imagecontainer">
                                                                            <div class="overlay" >
                                                                                <PremiumIcon
                                                                                    width={"30px"}
                                                                                    style={{ cursor: "pointer" }}
                                                                                />
                                                                            </div>
                                                                            <img onClick={() => handleOpen(item?.previewImage, item.id, item.category)}
                                                                                src={item?.imageUrl} alt={item?.name} draggable="false"
                                                                                style={{
                                                                                    height: '200px',
                                                                                    width: '260px',
                                                                                    borderRadius: '20px',
                                                                                    padding: "5px",
                                                                                    border: '1px solid rgba(51, 125, 200, 1)',
                                                                                    boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.2)'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <img onClick={() => handleOpen(item?.previewImage, item.id, item.category)}
                                                                            src={item?.imageUrl} alt={item?.name}
                                                                            draggable="false"
                                                                            className="ImageTransition" />
                                                                    )}
                                                                <div className="titleStyle">
                                                                    <Typography fontSize={'14px !important'}>{item?.name}</Typography></div>
                                                            </Grid>
                                                        ))}
                                                </Carousel>
                                            </Box>
                                        );
                                    })}
                                </Grid>

                                {/* ---------------------------------Recently Viewed------------------- */}

                                <Grid>
                                    {role === "CUSTOMER" && recentlyViewedTemp !== null && recentlyViewedTemp.length > 0 ? (
                                        <Box sx={{ marginBottom: '10px' }}>
                                            <Grid container display={'flex'} >
                                                <Typography className="tempHeading1"> {'Recently Viewed'}</Typography> &nbsp;
                                                {/* <Typography className="tempHeading2">See All</Typography> */}
                                            </Grid>
                                            <Carousel dynamic={true} leftArrow={false}
                                                show={4}
                                                slide={3}
                                                swiping={true}
                                                pageCount={5}
                                            >
                                                {recentlyViewedTemp.map((item) => (
                                                    <Grid key={item.id}
                                                        style={{
                                                            cursor: "pointer",
                                                            textAlign: "center",
                                                            top: "982px",
                                                            width: "348px",
                                                            height: "289px",
                                                            opacity: "1",
                                                        }}
                                                    >
                                                        {item?.advertType === 'premium' ?
                                                            (
                                                                <div class="Imagecontainer">
                                                                    <div class="overlay" >
                                                                        <PremiumIcon
                                                                            width={"30px"}
                                                                            style={{ cursor: "pointer" }}
                                                                        />
                                                                    </div>
                                                                    <img onClick={() => handleOpen(item?.previewImage, item.id, item.tags)}
                                                                        src={item?.thumbnailImage} alt={item?.templateName} draggable="false"
                                                                        style={{
                                                                            height: '200px',
                                                                            width: '260px',
                                                                            borderRadius: '20px',
                                                                            padding: "5px",
                                                                            border: '1px solid rgba(51, 125, 200, 1)',
                                                                            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.2)'
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    onClick={() => handleOpen(item?.previewImage, item.id, item.tags)}
                                                                    src={item?.thumbnailImage} alt={item?.templateName}
                                                                    draggable="false"
                                                                    className="ImageTransition" />
                                                            )}

                                                        <div className="titleStyle">
                                                            <Typography fontSize={'14px !important'}>{item?.templateName}</Typography></div>
                                                    </Grid>
                                                ))}
                                            </Carousel>
                                        </Box>) : <></>}
                                </Grid>

                                {/* ---------------------------------Mostly Viewed------------------- */}
                                <Grid>
                                    {mostlyViewedTemp !== null && mostlyViewedTemp.length > 0 ? (
                                        <Box sx={{ marginBottom: '20px' }}>
                                            <Grid container display={'flex'} >
                                                <Typography className="tempHeading1"> {'Mostly Viewed'}</Typography> &nbsp;
                                                {/* <Typography className="tempHeading2">See All</Typography> */}
                                            </Grid>
                                            <Carousel dynamic={true} leftArrow={false}
                                                show={4}
                                                slide={3}
                                                swiping={true}
                                                pageCount={5}
                                            >
                                                {mostlyViewedTemp.map((item) => (
                                                    <Grid key={item.id}
                                                        style={{
                                                            cursor: "pointer",
                                                            textAlign: "center",
                                                            top: "982px",
                                                            width: "348px",
                                                            height: "289px",
                                                            opacity: "1",
                                                        }}
                                                    >
                                                        {item?.advertType === 'premium' ?
                                                            (
                                                                <div class="Imagecontainer">
                                                                    <div class="overlay" >
                                                                        <PremiumIcon
                                                                            width={"30px"}
                                                                            style={{ cursor: "pointer" }}
                                                                        />
                                                                    </div>
                                                                    <img onClick={() => handleOpen(item?.previewImage, item.id, item.tags)}
                                                                        src={item?.thumbnailImage} alt={item?.templateName} draggable="false"
                                                                        style={{
                                                                            height: '200px',
                                                                            width: '260px',
                                                                            borderRadius: '20px',
                                                                            padding: "5px",
                                                                            border: '1px solid rgba(51, 125, 200, 1)',
                                                                            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.2)'
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    onClick={() => handleOpen(item?.previewImage, item.id, item.tags)}
                                                                    src={item?.thumbnailImage} alt={item?.templateName}
                                                                    draggable="false"
                                                                    className="ImageTransition" />
                                                            )}

                                                        <div className="titleStyle">
                                                            <Typography fontSize={'14px !important'}>{item?.templateName}</Typography></div>
                                                    </Grid>
                                                ))}
                                            </Carousel>
                                        </Box>) : <></>}
                                </Grid>
                                {/* ---------------------------------Mostly Ordered------------------- */}
                                <Grid>
                                    {mostlyOrderdTemp !== null && mostlyOrderdTemp.length > 0 ? (
                                        <Box sx={{ marginBottom: '20px' }}>
                                            <Grid container display={'flex'} >
                                                <Typography className="tempHeading1"> {'Mostly Ordered'}</Typography> &nbsp;
                                                {/* <Typography className="tempHeading2">See All</Typography> */}
                                            </Grid>
                                            <Carousel dynamic={true} leftArrow={false}
                                                show={4}
                                                slide={3}
                                                swiping={true}
                                                pageCount={5}
                                            >
                                                {mostlyOrderdTemp.map((item) => (
                                                    <Grid key={item.id}
                                                        style={{
                                                            cursor: "pointer",
                                                            textAlign: "center",
                                                            top: "982px",
                                                            width: "348px",
                                                            height: "289px",
                                                            opacity: "1",
                                                        }}
                                                    >
                                                        {item?.advertType === 'premium' ?
                                                            (
                                                                <div class="Imagecontainer">
                                                                    <div class="overlay" >
                                                                        <PremiumIcon
                                                                            width={"30px"}
                                                                            style={{ cursor: "pointer" }}
                                                                        />
                                                                    </div>
                                                                    <img onClick={() => handleOpen(item?.previewImage, item.id, item.tags)}
                                                                        src={item?.thumbnailImage} alt={item?.templateName} draggable="false"
                                                                        style={{
                                                                            height: '200px',
                                                                            width: '260px',
                                                                            borderRadius: '20px',
                                                                            padding: "5px",
                                                                            border: '1px solid rgba(51, 125, 200, 1)',
                                                                            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.2)'
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <img
                                                                    onClick={() => handleOpen(item?.previewImage, item.id, item.tags)}
                                                                    src={item?.thumbnailImage} alt={item?.templateName}
                                                                    draggable="false"
                                                                    className="ImageTransition" />
                                                            )}

                                                        <div className="titleStyle">
                                                            <Typography fontSize={'14px !important'}>{item?.templateName}</Typography></div>
                                                    </Grid>
                                                ))}
                                            </Carousel>
                                        </Box>) : <></>}
                                </Grid>
                            </>
                        ) : (
                            <Grid container>
                                {searchedCategoryImages.map((item, index) => (
                                    <Grid item key={index} style={{
                                        cursor: "pointer", textAlign: "center",
                                        width: "auto", height: 'auto', padding: '7px', opacity: "1"
                                    }}

                                    >
                                        {item?.advertType === 'premium' ?
                                            (
                                                <div class="Imagecontainer">
                                                    <div class="overlay1" >
                                                        <PremiumIcon
                                                            width={"30px"}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </div>
                                                    <img onClick={() => handleOpen(item?.previewImage, item.id, item.category)}
                                                        src={item?.imageUrl} alt={item?.name} draggable="false"
                                                        style={{
                                                            height: '200px',
                                                            width: '280px'
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <img onClick={() => handleOpen(item?.previewImage, item.id, item.category)}
                                                    src={item?.imageUrl} alt={item?.name} draggable="false"
                                                    style={{
                                                        height: '200px',
                                                        width: '280px'
                                                    }}
                                                    className="ImageTransition"
                                                />
                                            )}
                                        <Grid ml={1.5}> <Typography>{item?.name}</Typography></Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                }
            </ThemeProvider >
            <Dialog open={open} onClose={handleClose} maxWidth="md" className={classes.customClass}
                style={{ backdropFilter: 'blur(5px)' }} >
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: '#fff' }}>Image Preview</Typography>
                        <div style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                            {editCustomer &&
                                <EditIcon onClick={handleEdit} style={{ cursor: "pointer", marginRight: '15px', color: '#fff' }} />
                            }
                            <CloseIcon onClick={handleClose} className={classes.btnStyle} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className="container-image-new">
                    <img src={selectedImageUrl} alt="Selected Template" draggable="false" />
                </DialogContent>
            </Dialog>
        </Grid>
    );
}
