import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Pagination, InputLabel, Select } from '@mui/material';
import { Box } from '@mui/system';
import { NoResultFound } from './NoResultFound';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useEffect } from 'react';

const customDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#ebebeb !important',
    color: '#474B4F !important',
    opacity: '0.8!important',
    borderBottom: '0px solid #E7EBF0',
    fontSize: '14px !important'
  },
  '.MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
  '.MuiDataGrid-cell': { borderBottom: '0px', color: '#304669', opacity: '0.9', fontSize: "12px" },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none!important',
    color: '#1487CA!important',
  },
  '.MuiDataGrid-columnSeparator': { display: 'none !important' },
  '.MuiSvgIcon-root': { backgroundColor: 'transparent' },
  '.MuiDataGrid-root': { padding: '8px', borderRadius: '4px' },
  '.MuiDataGrid-row': {
    borderBottom: '1px solid #E7EBF0',
    maxHeight: '48px!important',
    minHeight: '48px!important',
  },
  '.MuiCheckbox-root': { color: '#D1D1D1' },
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px',
  },
  '.MuiDataGrid-virtualScrollerRenderZone': { backgroundColor: '#FFFF' },
  '.MuiDataGrid-footerContainer': {
    display: 'block',
    width: '100%',
    border: '0px',
  },
  '.MuiTablePagination-spacer': { display: 'none' },
  '.MuiTablePagination-actions': {
    position: 'absolute',
    right: '0px',
    backgroundColor: '#FFF',
  },
  '.MuiTablePagination-displayedRows': { position: 'absolute', right: '90px' },
  // '.MuiDataGrid-virtualScroller': { overflowY: 'auto !important', height: '300px !important', overflowX: 'hidden !important' },
  '.MuiTablePagination-select': {
    backgroundColor: '#F0F1F4',
    borderRadius: '4px!important',
  },
  '.MuiTablePagination-toolbar': { paddingLeft: '0px' },
  '.MuiIconButton-root.Mui-disabled': {
    backgroundColor: '#F0F1F4',
    borderRadius: '0px',
  },
  '.MuiIconButton-root': { borderRadius: '0px' },
  '.Mui-checked': { color: 'rgb(54, 201, 109)!important' },
};
export default function DataTable(props) {
  const [loader, setLoader] = useState(false);

  const setLoaderFunction = () => {
    const isLoading = localStorage.getItem("isLoading");
    setLoader(isLoading === "true");
  };

  useEffect(() => {
    setLoader(true);

    const timerId = setTimeout(() => {
      setLoader(false);
      localStorage.setItem("isLoading", "false");
    }, 5000);

    window.addEventListener("storage", setLoaderFunction);
    return () => {
      clearTimeout(timerId);
      window.removeEventListener("storage", setLoaderFunction);
    };
  }, []);

  const theme = useTheme();
  const getPageDetails = () => {
    var pageRange = '0 - 0 of 0';
    var page = props.page + 1;
    if (props.totalRecords > 0) {
      let lastRecordIndex = page * props.rowsPerPage;
      if (lastRecordIndex <= props.totalRecords) {
        let firstRecordIndex = lastRecordIndex - props.rowsPerPage + 1;
        pageRange = firstRecordIndex + '-' + lastRecordIndex + ' of ' + props.totalRecords;
      }
      else {
        let firstRecordIndex = lastRecordIndex - props.rowsPerPage + 1;
        pageRange = firstRecordIndex + '-' + props.totalRecords + ' of ' + props.totalRecords;
      }
    }
    return pageRange;
  };

  const noResultFound = (message) => {
    return (
      <>
        <NoResultFound message={message} />
      </>
    );
  };
  return (
    <div style={{ height: '540px', width: '100%' }}>
      <DataGrid
        {...props}
        hideFooter='true'
        paginationMode='server'
        page={props.page}
        rows={props.rows}
        columns={props.columns} 
        // autoHeight
        rowHeight={50}
        pagination
        rowCount={props.totalRecords}
        checkboxSelection={false}
        disableColumnMenu
        style={{ border: 'none' }}
        sx={customDataGridStyle}
        hideFooterSelectedRowCount={true}
        pageSize={props.rowsPerPage}
        onPageSizeChange={(newPageSize) => props.onPageSizeChange(newPageSize)}
        onSelectionModelChange={(params) => {
          props.onSelection(params);
        }}
        onPageChange={(params) => props.onPageChange(params)}
        components={{
          NoRowsOverlay: () => noResultFound(props.noResultFoundMessage ? props.noResultFoundMessage : "No Record Found"),
        }}
        loading={loader}
      />
      {props.hidePagination === undefined && <Box display={'flex'} justifyItems='center'>
        <Box item flexGrow={1} pl={1} display='flex' pt={1}>
          <Box pt={1}>
            <InputLabel htmlFor='filled-age-native-simple'>No of Records Per Page</InputLabel>
          </Box>
          <Box pl={1}>
            <Select
              sx={{ padding: '0px 17px 0px 0px!important', marginBottom:'5px'}}
              native
              size='small'
              value={props.rowsPerPage}
              // onChange={handleChange}
              inputProps={{
                name: 'age',
                id: 'filled-age-native-simple',
              }}
              onChange={(e) => props.onPageSizeChange(e.target.value)}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              {/* <option value={props.rowsPerPage}>{props.rowsPerPage}</option> */}
            </Select>
          </Box>
          <Box pl={1} pt={1}>
            <InputLabel htmlFor='filled-age-native-simple'>{getPageDetails()}</InputLabel>
          </Box>
        </Box>
        <Box item pr={1} pt={1}>
          <Pagination
            count={Math.ceil(props.totalRecords / props.rowsPerPage)} sx={[() =>
              ({ button: { backgroundColor: "#F2F2F2", color: '#637381', }, }),
            () => ({ ul: { "& .Mui-selected": { backgroundColor: theme.palette.primary.main, color: 'white', }, }, }),
            () => ({ ul: { "&:hover": { "& .Mui-selected": { backgroundColor: theme.palette.primary.light, }, }, }, }),]}
            shape='rounded'
            page={props.page + 1}
            onChange={(e, v) => props.onPageChange(v - 1)} />
        </Box>
      </Box>}
    </div>
  );
}
DataTable.defaultProps = {
  rows: [
    {
      id: 1,
      key: 1,
      Zonename: 'RND',
      Sitename: 'RND Center',
      country: 'Spain',
      Province_state: 'Guangdong',
      City: 'Donguang',
      Zonecode: 'DSZ34',
      Sitecode: '00123',
      Address: 'Lorem Ipsum is simply dummy',
    },
    {
      id: 2,
      key: 2,
      Zonename: 'RND',
      Sitename: 'RND Center',
      country: 'Donguang',
      Province_state: 'Guangdong',
      City: 'Donguang',
      Zonecode: 'DSZ34',
      Sitecode: '00123',
      Address: 'Lorem Ipsum is simply dummy ',
    },
  ],
  columns: [],
};
