import { Box, Grid, Tooltip } from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as DeleteOutlineIcon } from "../../../assets/images/Delete Icon (2).svg";
import { ReactComponent as ModeEditIcon } from "../../../assets/images/Edit Icon (1).svg";
import { ReactComponent as AddIcon } from "../../../assets/images/Plus icon (1).svg";
import { ReactComponent as Refresh } from "../../../assets/images/refresh_Icon.svg";
import DataTable from "../../../components/DataTable";
import IconHandler from "../../../components/IconHandler";
import RightViewLayout from "../../../components/RighViewLayout";
import { REACT_EDIT_TAGS } from "../../../constants/ReactEndPoints";
import TagService from "../../../services/TagService";

export default function Tags() {
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const navigate = useNavigate();
  const [pageOperation, setPageOperation] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [rows, setRows] = useState([]);
  const [callApiFilter, setCallApiFilter] = useState(true);

  useEffect(() => {
    if (callApiFilter) {
      fetchTags();
      setCallApiFilter(false);
    }
  }, [callApiFilter]);

  const handleAddUser = () => {
    navigate('/AddTags')
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1.7,
      width: 200,
      renderCell: (e) => <input type="hidden" value={e.row.id} />,
      editable: false,
      hide: true,
      headerAlign: 'left',
      align: "left",
    },
    {
      field: "tag",
      headerName: t("TAG02"),
      flex: 1.7,
      width: 200,
      renderCell: (e) => e.row.tag,
      editable: false,
      headerAlign: 'left',
      align: "left",
    },
    {
      field: "type",
      headerName: t("TAG03"),
      flex: 1.2,
      width: 200,
      renderCell: (e) => e.row.type,
      editable: false,
      headerAlign: 'left',
      align: "left",
    },
    {
      field: "sequenceOder",
      headerName: t("TAG04"),
      // flex: 1.2,
      width: 250,
      renderCell: (e) => e.row.sequenceOder,
      editable: false,
      headerAlign: 'left',
      align: "left",
    },
    {
      headerName: t("USER10"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      renderCell: (e) => (
        <IconHandler>
          <>
            <Tooltip title={t("USER11")} arrow>
              <ModeEditIcon id="editTags-icon"
                onClick={() => {
                  if (e.row.type !== 'Default') {
                    handleActions("Edit", e);
                  }
                }}
                style={{
                  margin: "7px",
                  width: '1em',
                  cursor: e.row.type !== 'Default' ? 'pointer' : 'not-allowed',
                  pointerEvents: e.row.type !== 'Default' ? 'auto' : 'none',
                  opacity: e.row.type === 'Default' ? 0.3 : 1,
                }}
              />
            </Tooltip>
            <Tooltip title={t("USER12")} arrow>
              <DeleteOutlineIcon id="deleteTags-icon"
                onClick={() => {
                    handleActions("Delete", e);
                }}
                style={{
                  margin: "7px",
                  width: '0.8em',
                }}
              />
            </Tooltip>
          </>
        </IconHandler>
      ),
    },
  ];

  const [id, setId] = useState("");


  const handleActions = (action, data) => {
    if (action === "Edit") {
      const tagId = data['id'];
      setId(tagId);
      console.log(data.row);
      navigate(REACT_EDIT_TAGS, { state: { rowData: data.row } });
    }
    else if (action === "Delete") {
      TagService.deleteTagById(data["id"]).then((res) => {
        if (res.data.code === 'ZKCSAI0001') {
          toast.success('Tags deleted successfully', {
            position: "top-right"
          });
          fetchTags();
        }
      });
    }
  };

  const handleSelectedRows = (data) => {
    setSelectedEmployee(data);
  };

  const handleRefresh = () => {
    fetchTags(true);
    setPageOperation({ page: 0, rowsPerPage: 10 });
    setCallApiFilter(true);
  }

  const fetchTags = () => {
    TagService.getAllTags(pageOperation.page + 1, pageOperation.rowsPerPage).then((res) => {
      if (res !== undefined) {
        if (res.data.code === "ZKCSAI0001") {
          setRows(res.data.data.tagsList);
          setTotalRecords(res?.data?.data?.totalCount);
        } else {
          setRows([]);
          setTotalRecords(0);
        }
      }
    });
  }

  const handleChangePage = (newPage) => {
    setPageOperation({ ...pageOperation, page: newPage });
    setCallApiFilter(true);
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setPageOperation({ ...pageOperation, page: 0, rowsPerPage: pageSize });
    setCallApiFilter(true);
  };


  const dataTableStyle = {
    "& .MuiDataGrid-colCell": {
      backgroundColor: "red",
      color: "white",
    },
  };


  const actions = () => (
    <Box item style={{ display: "inherit" }}>
      <Tooltip title={t("USER2")} arrow>
        <Refresh id="refresh-tags"
          width={"40px"}
          style={{ marginRight: "10px", cursor: "pointer" }}
          onClick={() => {
            handleRefresh();
          }}
        />
      </Tooltip>
      <Tooltip arrow title={t("USER4")}>
        <AddIcon id="addTags-icon"
          width={"40px"}
          onClick={() => handleAddUser()}
          style={{ marginRight: "10px", cursor: "pointer" }}
        />
      </Tooltip>
    </Box>
  );

  return (
    <Grid >
      <RightViewLayout
        title={t("TAG01")}
        actions={actions}
        type="table"    >
        <Grid container margin='auto' paddingLeft={'0px'} overflow={"initial"} display="flex" >
          <DataTable
            columns={columns}
            rows={rows}
            headerClassName="custom-header-color"
            style={{ dataTableStyle }}
            onSelection={handleSelectedRows}
            page={pageOperation.page}
            totalRecords={totalRecords}
            rowsPerPage={pageOperation.rowsPerPage}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Grid>
      </RightViewLayout>
    </Grid>
  );
}
